.homepage-search {
    .SumoSelect {
        &.sumo_type {
            .CaptionCont {
                display: block !important;
            }
        }
    }
}

.search-selects {
    background: transparent;
    padding: 0;
    color: #1b262e;
    font-size: 13px;

    .input-column {
        height: 50px;

        input {
            padding: 0 15px;
            width: 170px;
            height: 50px;
            border: none;
        }
    }

    .select-column {
        width: 174px;
        height: 50px;

        .SumoSelect {
            > .CaptionCont {
                border: none;
                margin-left: 1px;
                width: initial;
                text-align: left;

                > span {
                    height: 50px;
                    line-height: 50px;

                    &.placeholder {
                        color: #1b262e;
                        font-size: 13px;
                        font-style: normal;
                    }
                }

                > label {
                    > i {
                        line-height: 50px;
                    }
                }
            }

            > .optWrapper {
                top: 51px;
                border: none;

                .options {
                    li {
                        text-align: left;

                        label {
                            position: relative;
                            top: -1px;
                        }

                        &.opt {
                            padding-left: 30px !important;

                            span {
                                width: 20px;
                                margin-left: -25px;
                            }
                        }
                    }
                }
            }
        }
    }

    .range-col {
        background: #FFF;
        height: 50px;
        margin-left: 1px;

        .range-wrapper {
            left: 0;
            width: 198px;
        }

        > p {
            color: #1b262e;
        }
    }

    button {
        width: 50px;
        font-size: 20px;
    }

    &.group-search {
        border-bottom: 0;
        margin: -10px 0;

        .col {
            border-left: 1px solid #d4d4d4;
        }
    }

    .search-box-triggers {
        color: #FFF;
        margin-top: 20px;
        float: right;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, .7);
        font-size: 15px;
    }
}