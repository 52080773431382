body#property-map-view #map-canvas {
    height: 650px;
}

.map-wrapper {
    position: relative;

    .map-overlay {
        position: absolute;
        background: rgba(0, 0, 0, .5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9;
        display: none;
    }

    .map-preloader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
        height: 100px;
        display: none;
        z-index: 10;

        i {
            font-size: 100px;
            color: color('secondary');
        }
    }
}

@media(max-width: 660px) {
    .map-wrapper {
        height: 300px;
    }

    #map-canvas {
        height: 300px !important;
    }
}

.infoBox {
    position: absolute;
    width: 315px;
    text-align: left;
    margin-top: -20px;
    z-index: 100;

    .infoBoxArrow {
        background: url('https://www.biliskov.com/site_resources/images/gmap/infoBox_pointer.png') 0 18px no-repeat;
        position: absolute;
        width: 18px;
        top: 0;
        left: 0;
        bottom: 0;
    }
}

.infoBoxInner {
    width: 300px;
    margin-left: 18px;
    min-height: 97px;
    max-height: 270px;
    border: 1px solid #e4e4df;
    border-left: none;
    padding: 10px 6px 20px 12px;
    background: url('https://www.biliskov.com/site_resources/images/gmap/ajax-loader-cs.gif') 50% 50% no-repeat #fff;
    overflow: auto;
    line-height: 16px;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.gmap-info-holder:first-of-type {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.gmap-info-holder:nth-of-type(2) {
    padding-top: 11px;
    margin-bottom: 0px;
}

.gmap-info-holder {
    padding-bottom: 11px;
    margin-bottom: 10px;
}

.infoBoxInner {
    font-family: Rajdhani, sans-serif;

    .pull-left {
        width: 40%;

        img {
            width: 100%;
            margin-top: 2px;
        }
    }

    .pull-right {
        width: 57%;
        font-size: 14px;
        line-height: 15px;
        font-weight: 600;
        margin-left: 3%;

        a.title {
            display: block;
            margin-bottom: 10px;
            color: #282323;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-transition: all .15s ease-out;
            -moz-transition: all .15s ease-out;
            -o-transition: all .15s ease-out;
            transition: all .15s ease-out;
            font-weight: 600;
            height: auto;

            &:hover {
                color: color('primary');
            }
        }

        p {
            color: #333;
            font-weight: 500;

            span {
                color: #888;
            }
        }

        .price {
            margin-top: 10px;
            color: color('primary');
            font-weight: 600;
        }
    }

    .more {
        border-top: 1px solid #333;
        padding: 5px;
        text-align: right;
        position: absolute;
        width: 282px;
        bottom: 0;
        left: 18px;
        background-color: #fff;

        a {
            cursor: pointer;
            line-height: 20px;
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
            color: #333;
            text-transform: capitalize;

            &:hover {
                color: #e3210b;
            }

            &.show-all {
                float: left;
            }

            &.cluster-too-big {
                float: left;
                font-size: 10px;
                max-width: 180px;
                cursor: auto !important;
                text-align: left;
                font-weight: 300;
                line-height: 11px;
                text-transform: none;

                &:hover {
                    cursor: auto !important;
                    text-decoration: none;
                    color: #333;
                }
            }

            i {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 660px) {
    .infoBox {
        width: 155px;
    }

    .infoBoxInner, .infoBoxInner .more {
        width: 140px;
    }

    .box-map-infowindow {
        min-width: 104px;
        max-width: 104px;
    }

    .infoBoxInner .pull-left,
    .infoBoxInner .pull-right {
        width: 100%;
    }

    .infoBoxInner .pull-right a.title {
        height: 45px;
        width: 104px;
    }

    .infoBoxInner .pull-left img {
        max-width: 104px;
    }
}

.active-ctrl-button .control-button-holder {
    border-color: color('primary') !important;
}

.active-ctrl-button div * {
    color: color('primary') !important;
}

.disabled .control-button-holder {
    opacity: .5;
    cursor: not-allowed !important;
}

.map-help,
.map-error {
    display: none;
    position: fixed;
    background: #fff;;
    width: 500px;
    height: 225px;
    padding: 20px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.map-img-help {
    display: none;
    position: absolute;
    width: 494px;
    padding: 20px;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    left: 210px;
    top: 260px;
    font-size: 24px;
    font-weight: 300;

    img,
    p {
        float: left;
    }

    p {
        margin-top: 50px;
        color: #fff;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.mih-text {
    float: left;
    margin-top: 140px;
    margin-left: 20px;
    color: #fff;
}

.mih-imgs {
    margin-top: 15px;
    padding-left: 80px;
}

@media (max-width: 767px) {
    .map-img-help {
        width: 280px;
        padding: 20px;
        right: 0;
        bottom: 0;
        z-index: 999;
        left: 0;
        top: 145px;
        font-size: 14px;
        font-weight: 300;
        margin: auto;
    }

    .mih-arrow {
        height: 50px;
        float: none !important;
        display: block;
        margin: auto;
        margin-bottom: 20px;
    }

    .mih-text {
        float: none;
        margin-top: 0px;
        margin-left: 0px;
        text-align: center;
    }

    .mih-imgs {
        margin-top: 15px;
        padding-left: 50px;
    }

    .mih-imgs img {
        width: 50px;
    }

    .map-img-help p {
        margin-top: 21px;
        color: #fff;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media(max-width: 660px) {
    .map-help,
    .map-error {
        width: 75%;
        height: 250px;
    }
}

@media(max-width: 480px) {
    .map-help,
    .map-error {
        width: 75%;
        height: 290px;
    }
}

.close-help,
.close-error {
    float: right;
    font-size: 23px;
    margin-top: -20px;
    margin-right: -14px;
    cursor: pointer;

    &:hover {
        color: #ac000c;
    }
}

.control-button-holder {
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 6px;
    margin: 20px 0 0 10px;
    cursor: pointer;
    text-align: center;
}

.poly-map-button {
    border-radius: 3px;
    display: block;

    &.draw,
    &.delete {
        background: #FFF;
        padding: 9px 10px 8px;

        i {
            width: 18px;
            height: 18px;
            display: inline-block;
        }
    }

    &.draw {
        i {
            background: url("../../images/icons/poly-rectangle.png") no-repeat center center;
        }
    }

    &.delete {
        i {
            background: url("../../images/icons/poly-trash.png") no-repeat center center;
        }
    }

    &.search {
        background: color('primary');
        color: #FFF;
        font-size: 15px;
        font-weight: 400;
        padding: 10px 15px;

        i {
            font-size: 14px;
            margin-left: 10px;
        }

        &.disabled {
            opacity: 0.6;
        }
    }
}

.poly-help-button {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: #666;
    border-radius: 3px;
    display: block;
    background: #FFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    margin: 10px 10px 0 0;
    padding: 8px 14px;

    &:hover {
        cursor: pointer;
    }
}

// MAP INFO BOX STYLE
.gm-style {
    .gm-style-iw-c {
        border-radius: 0;
    }

    .gm-ui-hover-effect {
        opacity: 1 !important;
    }

    .gm-style-iw {
        overflow: visible !important;

        button {
            background: #FFF !important;
            top: -18px !important;
            right: -17px !important;
            width: 34px !important;
            height: 35px !important;
            padding: 10px !important;
            border-radius: 20px !important;

            img {
                margin: 0 !important;
            }
        }
    }
}