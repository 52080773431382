@import './group_view.scss';
@import './group_item.scss';
@import './single_view.scss';
@import './print.scss';

.property-patch {
    position: absolute;
    top: 10px;
    left: 10px;
    text-transform: uppercase;
    z-index: 1;
    background: #ff0000;
    line-height: 1;
    padding: 10px 15px;
    color: #fff;
}

.patch-wrapper {
    position: relative;
}