#inquiry,
#forgotten-password {
    .modal-dialog {
        position: absolute;
        top: 120px;
        right: 100px;
        bottom: 0;
        left: 0;
        z-index: 10040;
        overflow: auto;
        overflow-y: auto;

        @media only screen and (max-width: 350px) {
            max-width: 310px;
            top: 0;
        }

        @media screen and (min-width:359px) and (max-width:370px) {
            max-width: 350px;
            top: 0;
        }

        @media screen and (min-width:370px) and (max-width:600px) {
            max-width: 400px;
            top: 0;
        }
    }
}

#forgotten-password {
    .modal-content {
        .modal-header {
            background-color: #1b262e;

            h5 {
                font-size: 21px;
                color: #fff;
                font-weight: 400;
            }

            button {
                font-size: 31px;
                color: #fff;
                font-weight: 100;
                padding: 0 1rem !important;

                span {
                    font-size: 55px;
                }
            }
        }

        .modal-body {
            p {
                color: #000;
                font-size: 14px;
                margin-left: 2px;
            }

            input {
                margin-top: 10px;
                padding: 20px;
            }
        }

        .modal-footer {
            border-top: none;
            justify-content: unset !important;

            button {
                text-transform: uppercase;
                border-radius: 0;
                padding: 7px 20px 7px 20px;
                margin-right: 75px;

                i {
                    margin-left: 15px;
                }
            }

            p {
                margin-left: 75px;

                i {
                    color: #0087ea;
                    font-size: 9px;
                    position: relative;
                    bottom: 3px;
                }
            }
        }

        @media only screen and (max-width: 355px) {
            .modal-footer {
                p {
                    margin-left: 0;
                }
            }
        }
    }

}

#inquiry {
    .modal-content {

        .modal-header {
            background-color: #1b262e;

            h5 {
                font-size: 21px;
                color: #fff;
                font-weight: 400;
            }

            button {
                font-size: 31px;
                color: #fff;
                font-weight: 100;
                padding: 0 1rem !important;

                span {
                    font-size: 55px;
                }
            }

        }

        .modal-body {
            margin-top: 15px;

            p {
                color: #000;
                font-size: 14px;
                margin-left: 2px;
                margin-top: 3px;
            }

            input {
                margin-top: 10px;
                padding: 20px;
            }

            textarea {
                margin-top: 15px;

            }

            form {
                padding: 10px;
            }

            .form-check {
                margin-bottom: 15px;
                border-bottom: 1px solid #cecece;

                &:nth-child(2) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                label {
                    margin-bottom: 10px;
                    margin-left: 10px;
                    font-weight: 600;
                    color: #1b262e;
                    font-size: 15px;
                }

                input {
                    margin-top: 4px;
                    margin-bottom: 15px;
                    border-radius: 0;

                }

                .add-mail {
                    margin-left: 10px;
                    margin-bottom: 25px;

                    i {
                        margin-left: 7px;
                    }
                }

                .form-control {
                    margin-left: 10px;
                    width: 97.5%;
                }
            }

            .last-check {
                border-bottom: none;
                margin-top: 25px;
                margin-bottom: 20px;
            }

            /* Hide the browser's default checkbox */
            .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #ffffff;
                border: 0.5px solid #7e7e7e;
            }

            /* On mouse-over, add a grey background color */
            .container:hover input~.checkmark {
                background-color: rgb(238, 238, 238);
            }

            /* When the checkbox is checked, add a blue background */
            .container input:checked~.checkmark {
                background-color: #ffffff;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            .container input:checked~.checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            .container .checkmark:after {
                left: 7px;
                top: 3px;
                width: 8px;
                height: 13px;
                border: solid #4d4d4d;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .modal-footer {
            border-top: none;
            justify-content: unset !important;

            button {
                text-transform: uppercase;
                border-radius: 0;
                padding: 7px 20px 7px 20px;
                margin-right: 65px;

                i {
                    margin-left: 15px;
                }
            }

            p {
                margin-left: 70px;

                i {
                    color: #0087ea;
                    font-size: 9px;
                    position: relative;
                    bottom: 3px;
                }
            }
        }

        @media only screen and (max-width: 355px) {
            .modal-body {
                margin-top: 0;

                form {
                    padding: 0;
                }
            }

            .modal-footer {
                p {
                    margin-left: 0;
                }
            }
        }
    }
}