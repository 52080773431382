.nav {
    &.nav-tabs {
        justify-content: center !important;
        text-align: center !important;
        border-bottom: none !important;
        margin-bottom: 30px;

        @media only screen and (max-width: 600px) {
            nav ul {
                display: none;
            }
        }

        li {
            margin: 0 25px;

            @media only screen and (max-width: 600px) {
                margin: auto;
            }

            .title {
                font-size: 30px;
                font-weight: 500;
                position: relative;
                padding-bottom: 20px;
                display: inline-block;
                color: color('primary');

                a {

                    }

                &:hover {
                    text-decoration: none;
                }

                &:after {
                    background-color: color('secondary');
                    content: '';
                    display: block;
                    height: 2px;
                    left: 50%;
                    bottom: 0;
                    position: absolute;
                    transform: translate(-50%, 0);
                    width: 100px;

                    @media only screen and (max-width: 600px) {
                        bottom: 0 !important;
                    }
                }

                @media only screen and (max-width: 600px) {
                    font-size: 26px !important;
                }

                @media (max-width: 900px) and (min-width: 600px) {
                    font-size: 20px !important;
                }
            }

            @media only screen and (max-width: 600px) {
                .title:after {
                    left: 50%;
                    width: 30px;

                }

                .title:after {
                    width: 30px;
                }
            }

            @media only screen and (max-width: 960px) {
                .title:after {
                    left: 50%;
                    width: 50px;
                }

                .title:after {
                    left: 50%;
                    width: 50px;
                }
            }
        }
    }
}