@import "~select2/dist/css/select2.min.css";

body {
    #detail-search,
    &#homepage {
        .select2-container--default {
            height: 47px;
            max-width: 100%;

            .select2-selection--multiple {
                .select2-selection__rendered {
                    padding-left: 8px;
                }

                .select2-search {
                    line-height: 47px;
                }

                .select2-selection__choice {
                    font-size: 13px;
                    padding: 4px 10px;
                    margin-right: 7px;
                    margin-top: 9px;
                }
            }
        }
    }
}

.select2-container--default {
    height: 35px;
    flex: 1 1 auto;
    background: #FFF;

    @media (max-width: 992px) {
        height: 47px;
    }

    &.select2-container--focus {
        border-color: #f1ad9e;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(225, 79, 47, 0.25);

        .select2-selection--multiple {
            border: 0;
        }
    }

    .select2-selection--multiple {
        background: transparent;
        border: 0;
        border-radius: 0;

        .select2-selection__rendered {
            padding-left: 4px;

            @media (max-width: 992px) {
                line-height: 37px;

                .select2-selection__choice {
                    line-height: 22px;
                }
            }
        }

        .select2-search {
            font-size: 14px;
            line-height: 20px;

            @media (max-width: 992px) {
                padding-left: 8px;
                line-height: 47px;
            }
        }

        .select2-selection__choice {
            font-size: 12px;
            background: #fff;
            border: 1px solid #e0e0e0;
            padding: 2px 6px;
            margin-right: 5px;
            margin-top: 0;
            color: #222222;
            line-height: 18px;

            @media (min-width: 1024px) {
                margin-top: 6px;
            }

            @media (max-width: 992px) {
                font-size: 13px;
                padding: 4px 10px;
                margin-right: 7px;
                margin-top: 7px;
            }
        }

        .select2-selection__choice__remove {
            float: right;
            margin-left: 5px;
            margin-right: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 1;
            position: relative;
            top: -1px;
            color: #222222;
            z-index: 1020;
        }
    }

    .select2-dropdown {
        border-radius: 0;

        .select2-results {
            > .select2-results__options {
                .select2-results__option {
                    font-size: 14px;
                    line-height: 1;

                    &.select2-results__option--highlighted,
                    &[aria-selected=true] {
                        background-color: color('primary');
                        color: #FFF;
                    }
                }
            }
        }
    }
}