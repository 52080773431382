#saving-search-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    &.modal-hide {
        display: none;
    }

    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        border: 1px solid rgba(color('primary'), 0.3);
        width: 450px;

        @media(max-width: 768px) {
            width: 370px;
        }

        @media(max-width: 576px) {
            width: 330px;
        }

        .modal-top {
            padding: 20px;
            background-color: color('primary');
            color: #eee;

            p {
                margin: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 300;
                width: 49.99%;
                line-height: 2;
            }

            .close {
                color: #fff;

                span {
                    font-size: 40px;
                }
            }

            span {
                width: 49.99%;
                text-align: end;

                i {
                    font-size: 20px;
                    line-height: 1.5;
                    cursor: pointer;
                }
            }
        }

        .modal-form {
            label {
                font-size: 15px;
            }

            .input-wrapper {
                position: relative;

                input {
                    padding: 8px;
                    margin-bottom: 15px;
                    width: 100%;
                }

                &::after {
                    position: absolute;
                    z-index: 2;
                    top: -8px;
                    right: -5px;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    font-size: 9px;
                    content: "\f005";
                    color: #151e3a;
                }
            }

            select {
                padding: 10px;
                margin-bottom: 15px;
                width: 100%;
            }

            .modal-save-btn {
                padding: 10px 18px;
                text-transform: uppercase;
                color: #fff;
                background-color: color('primary');
                font-size: 15px;
                font-weight: 300;

                &[disabled] {
                    background: #8896b0;

                    &:hover {
                        cursor: default;
                    }
                }

                i {
                    margin-left: 10px;
                }
            }

            .modal-save-search-bottom {
                margin-top: 10px;

                span {
                    line-height: 3;

                    i {
                        margin: 0 3px 0 2px;

                        &:before {
                            position: relative;
                            top: -5px;
                            font-size: 9px;
                            color: color('primary');
                        }
                    }
                }
            }
        }
    }

    .alert-success {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            i {
                margin-right: 5px;
            }
        }
    }
}

.saving-search-modal-message {
    background-color: #d4edda;

    &.alert {
        position: fixed !important;
        margin-left: auto;
        transform: translate(-50%, -50%);
    }

    &.alert-error {
        background-color: #ef6464;
    }

    span {
        font-size: 15px;
    }

    a {
        font-weight: 700;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 30px;
        color: #ffffff;

        &:hover {
            color: #eeee;
        }
    }
}