.no-result-wrapper {
    .message {
        font-size: 20px;
        font-weight: 500;
        font-style: italic;
    }

    .look-again {
        display: block;
        font-size: 20px;
        font-weight: 500;
        color: color('primary');
        text-decoration: none;
    }

    p {
        font-size: 20px;
        margin-bottom: 0px;
    }

    a {
        font-size: 18px;
        color: color('primary');
        display: block;
        font-weight: 500;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}