#user-area-index {
    .form-group {
        input {
            padding: 23px;
            margin-bottom: 10px;
            border-radius: 0;
        }

        label {
            display: none;
        }
    }

    .help-block {
        margin-bottom: 10px;
        border: 1px solid color('error');
        display: block;
        padding: 20px;
        background: #ffe9e9;

        &.error-already-existing-user {
            i {
                color: color('error');
                font-size: 40px;
                float: left;
                margin-right: 20px;
            }
        }
    }

    .success-help-block {
        height: 106px;
        line-height: 3;
        align-items: center;
        justify-content: center;
        border: 1px solid #e0dcdc;
        display: block;
        padding: 30px;
        background: #eee;
        margin-bottom: 5px;

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .btn-success {
        color: #fff;
        background-color: #151e3a;
        border-color: #151e3a;
        border-radius: 0px;
        padding: 7px 25px;
        text-transform: uppercase;

        i {
            margin-left: 25px;
        }
    }

    a {
        .back-button {
            margin-bottom: 50px;
            color: #fff;
            background-color: #151e3a;
            border-color: #151e3a;
            border-radius: 0px;
            padding: 10px 15px;
            text-transform: uppercase;


            i {
                margin-right: 10px;
                font-size: 15px;
            }
        }
    }
}

#web_user {
    .form-group {
        position: relative;

        &::after {
            position: absolute;
            z-index: 50;
            top: -8px;
            right: -5px;
            font-family: 'Font Awesome 5 Free';
            font-size: 9px;
            font-weight: 900;
            content: "\f005";
            color: #151e3a;
        }

        &:nth-of-type(3)::after,
        &:nth-of-type(5)::after,
        &:nth-of-type(8)::after,
        &:nth-of-type(9)::after {
            content: none;
        }
    }

    .form-group:last-of-type {
        .checkbox {
            label {
                display: block;
                font-size: 15px;
                position: relative;

                input {
                    -webkit-appearance: none;
                    background-color: white;
                    border: 0.5px solid gray;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 11px;
                    margin-bottom: 0;
                    margin-right: 5px;
                    vertical-align: middle;
                    -moz-user-focus: ignore;

                    &:hover {
                        cursor: pointer;
                    }

                    &:active, &:checked:active {
                        //box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
                    }

                    &:checked {
                        background-color: white;
                        border: 1px solid #adb8c0;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
                        color: #99a1a7;
                    }

                    &:checked:after {
                        content: "";
                        position: absolute;
                        left: 8px;
                        top: 5px;
                        width: 8px;
                        height: 13px;
                        border: solid #4d4d4d;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }

                span {
                    display: block;
                    color: transparent !important;

                    &:after {
                        position: relative;
                        z-index: 50;
                        right: -325px;
                        top: -40px;
                        font-family: 'Font Awesome 5 Free';
                        font-size: 9px;
                        content: "\f005";
                        color: #151e3a;
                        font-weight: 900;
                    }
                }
            }
        }
    }

    a {
        color: color('error');
    }
}

#web_user[terms] {
    width: 20px;
    background-color: color('error');
}

.login {
    margin-bottom: 50px;

    @media(max-width: 768px) {
        //justify-content: center;
    }

    /* ADDITIONAL ADD FOR SYMFONY FORM */
    a {
        font-size: 14px;
    }

    .form-check {
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 1.65rem;

        p {
            padding-top: 0.2rem;
            margin-bottom: 0 !important;
        }
    }

    button {
        margin-top: 2rem;
    }

    .required {
        margin-top: 3rem;

        i {
            color: #151e3a;
            font-size: 9px;
            position: relative;
            top: -5px;
        }
    }

    /* ADDITIONAL ADD FOR SYMFONY FORM */
    .wrapper_1 {
        @media(min-width: 769px) {
            padding-right: 5rem;
        }

        @media(max-width: 768px) {
            margin-bottom: 50px;
        }
    }

    .wrapper_1:after {
        content: "";
        background-color: #c6c6c6;
        position: absolute;
        width: 1px;
        height: 730px;
        top: 0;
        left: 100%;
        display: block;

        @media only screen and (max-width: 900px) {
            display: none;
        }
    }

    .wrapper_2 {
        @media(min-width: 769px) {
            padding-left: 5rem;
        }
    }

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #ffffff;
        border: 0.5px solid gray;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: rgb(238, 238, 238);
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: #ffffff;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 7px;
        top: 3px;
        width: 8px;
        height: 13px;
        border: solid #4d4d4d;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .social {
        margin-bottom: 2rem;

        @media(max-width: 578px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .btn-link {
            text-decoration: none;
            display: inline-block;
            width: 49%;

            @media(max-width: 578px) {
                width: 100%;
                margin: 10px 0;
            }

            .signin-btn {
                border-radius: 0;
                padding: 2px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

                .logo {
                    height: 40px;
                    width: 50px;
                    text-align: center;
                    font-size: 25px;
                    line-height: 1.6;
                    background: #FFF;
                }

                .logo-text {
                    width: 100%;
                    font-family: Roboto, arial, sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    color: #FFF;
                    text-align: center;
                    padding: 0 10px;
                }
            }

            .btn-google {
                background: #4285f4;

                .logo {
                    color: #4285f4;
                }
            }

            .btn-facebook {
                background: #3b5998;

                .logo {
                    color: #3b5998;
                }
            }
        }
    }

    .cal {
        background-color: #ffffff;
        position: relative;
        width: 80px;
        padding: 0 10px;
    }

    h2 {
        margin-bottom: 40px;
        position: relative;

        &.booking-calendar-title {
            &::before {
                content: '';
                border: none;
                height: 1px;
                width: 100%;
                background: #c9c9c9;
                color: #c9c9c9;
                position: absolute;
                display: block;
                top: 50%;
            }
        }
    }

    .form-signin {
        .form-input {
            position: relative;

            &::after {
                position: absolute;
                z-index: 50;
                top: -8px;
                right: -5px;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                font-size: 9px;
                content: "\f005";
                color: #151e3a;
            }
        }

        .form-accept:after {
            position: relative;
            z-index: 50;
            right: -331px;
            top: -40px;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 9px;
            content: "\f005";
            color: #151e3a;
        }

        a {
            font-size: 14px;
            color: color('error');
        }

        .btn-primary {
            border-radius: 0;
        }

        input {
            padding: 23px;
            margin-bottom: 10px;
            border-radius: 0;
        }
    }
}