.header-container {
    .header-nav-main {
        .header-nav {
            .property-dropdown {
                .dropdown-menu {
                    width: 360px;
                    padding: 10px;
                    border: none;
                    left: 0 !important;

                    &.row-cols-1 {
                        width: 170px;

                        .navigation-column {
                            width: 100%;
                        }
                    }

                    li {
                        &:first-of-type {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}