.homepage-search.v4 {

    .first-col {
        input {
            width: 100%;
            padding: 0 8px;
        }
    }

    &.search-selects {


        .input-column {


            input {
                width: 94px
            }
        }

        .select-column {
            width: auto;
            height: 50px;

            .SumoSelect {
                > .CaptionCont {
                    border: none;
                    margin-left: 1px;
                    width: auto;
                    text-align: left;
                }
            }
        }

        .range-col {
            max-width: 12.28%;
            flex: 0 0 12.28%;
        }
    }

    .select-column-search {
        padding-left: 20px;
        margin: auto;
        width: 50%;
    }

    .no-gutters {
        text-align: left;
    }
}


.group-search.v4 {
    margin: 0;

    .first-col {
        border-left: 1px solid #d4d4d4;
        input {
            width: 100%;
            padding: 0 5px;
        }
    }

    .select-column-search {
        margin: auto;
        width: 50%;
        text-align: center;
    }

    .col-type {
        width: 50px;
    }

    .select-column,
    .first-col {
        border-right: 1px solid #d4d4d4;
    }

    // normal design
    .group-grid-action {
        width: 95px;
    }
    .group-grid-type {
        width: 163px;
    }
    .group-grid-county {
        width: 202px;
    }
    .group-grid-city {
        width: 155px;
    }
    .group-grid-quarter {
        width: 165px;
    }
    .group-grid-rooms{
        width: 95px;
    }
}



.col-type {
    width: 158px !important;
}

.col-narrower {
    width: 160px !important;
}

.col-county {
    width: 202px !important;
}

.col-city {
    width: 145px !important;
}


.range-col {
    padding: 0 !important;

    @media (max-width: 1170px) {
        p {
            padding: 0 5px;
        }
    }
}

// Fluid design
.group-grid {
    width: 250px !important;

    @media (max-width: 1780px) {
        width: 205px !important;
    }

    @media (max-width: 1590px) {
        width: 200px !important;
    }

    @media (max-width: 1535px) {
        width: 185px !important;
    }

    @media (max-width: 1490px) {
        width: 170px !important;
    }

    @media (max-width: 1397px) {
        width: 155px !important;
    }

    @media (max-width: 1289px) {
        width: 130px !important;
    }

    @media (max-width: 1289px) {
        width: 117px !important;
    }

    @media (max-width: 1070px) {
        width: 110px !important;
    }
}