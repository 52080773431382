@import "gallery_lightbox";

.ground-plans-wrapper,
.detail-gallery-wrapper {
    position: relative;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.visible {
        opacity: 1;
    }

    /*.tab-content {
        > .tab-pane {
            display: block;
            opacity: 0;
            z-index: -1;

            &.active {
                opacity: 1;
                z-index: 1;
            }
        }
    }*/

    .gallery-item {
        cursor: pointer;
        max-width: 100%;

        img {
            max-width: 100%;
        }
    }

    &::after {
        content: " ";
        height: 90px;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        bottom: 0;
        z-index: 2;
    }

    .ground-plans-gallery,
    .single-gallery {
        .slick-nav {
            font-size: 40px;
            color: #FFF;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            padding: 0 1.25rem;
            z-index: 9;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
            cursor: pointer;

            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                right: 0;
            }

            &.slick-disabled {
                opacity: 0.2;
            }
        }
    }

    ul {
        &.nav {
            display: inline-block;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1.25rem;
            z-index: 10;

            .nav-item {
                display: inline-block;
                font-size: 13px;
                line-height: 2;
                font-weight: 700;
                color: #FFF;
                border-right: 1px solid rgba(255, 255, 255, 0.5);
                padding-right: 15px;
                margin-right: 10px;
                vertical-align: middle;

                &:last-of-type {
                    border: 0;
                    padding-right: 0;
                    margin-right: 0;
                }

                &.active {
                    > a {
                        background-color: transparent;
                    }
                }

                > a {
                    background-color: transparent;
                    padding: 0;

                    span {
                        margin-left: 5px;
                    }

                    i {
                        font-size: 20px;
                        position: relative;
                        top: 2px;
                    }

                    &#ground-plans-tab {
                        width: 16px;
                        height: 16px;
                        background-image: url("../../images/icons/ground-plan-white.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    /*.card-img-overlay {
        bottom: 0;
        top: unset;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
        z-index: 3;

        ul {
            margin: 0;

            li {
                font-size: 12px;
                line-height: 2;
                font-weight: 700;
                color: #FFF;
                border-right: 1px solid rgba(255, 255, 255, 0.5);
                padding-right: 10px;
                margin-right: 6px;

                &:last-of-type {
                    border: 0;
                    padding-right: 0;
                    margin-right: 0;
                }

                span {
                    margin-left: 5px;
                }

                i {
                    font-size: 14px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }*/
}