#send-to-friend-modal {
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    border: 1px solid rgba(color('primary'), 0.3);
    width: 500px;

    @media(max-width: 768px) {
      width: 400px;
    }

    @media(max-width: 425px) {
      width: 320px;
    }

    .modal-top {
      padding: 10px 20px;
      background-color: color('primary');
      color: #eee;

      p {
        margin: 0;
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        width: 49.99%;
        line-height: 2;
      }

      button {
        color: #fff;

        span {
          font-size: 40px;
        }
      }
    }

    form {
      padding: 20px;



      .send-to-friend-wrapper {
        font-weight: 600;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
        margin-bottom: 25px;

        .input-wrapper {
          position: relative;

          &::after {
            position: absolute;
            z-index: 50;
            top: -8px;
            right: -5px;
            font-family: FontAwesome;
            font-size: 9px;
            content: "\f005";
            color: #151e3a;

          }
        }


        .additional-email {
          display: none;
          margin-bottom: 30px;

          &.show {
            display: block;
          }
        }


        input[type="email"] {
          padding: 8px 16px;
          margin-left: 25px;
          margin-bottom: 30px;
          margin-right: 0;
          width: calc(100% - 25px);
          border: 1px solid rgba(128, 128, 128, 0.3);
          &:first-of-type {
            margin-bottom: 0px;
          }

        }

        p {
          width: 30%;
          margin: 0 0 0 25px;
          font-size: 12px;
          cursor: pointer;

          &:hover {
            color: #000000;
          }
          span {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.5;
          }
        }

      }


      input[type="radio"],
      input[type="checkbox"] {
        margin-right: 10px;
        border: 1px solid rgba(128, 128, 128, 0.3);
      }

      .send-query {
        padding-bottom: 25px;
        font-weight: 600;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      }

      .send-copy-checkbox {

        input {
          position: relative;
          -webkit-appearance: none;
          background-color: white;
          border: 0.5px solid rgba(128, 128, 128, 0.3);
          box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
          padding: 8px;
          margin-bottom: 0;
          margin-right: 10px;
          vertical-align:middle;
          -moz-user-focus: ignore;

          &:hover {
            cursor: pointer;
          }

          &:checked {
            background-color: white;
            border: 1px solid #adb8c0;
            box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
            color: #99a1a7;
          }

          &:checked:after {
            content: "";
            position: absolute;
            left: 3px;
            top: 0px;
            width: 8px;
            height: 13px;
            border: solid #4d4d4d;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(
                            45deg
            );
            transform: rotate(
                            45deg
            );
          }
        }
      }

      .message-wrapper {

        input {
          padding: 5px 16px;
          margin-bottom: 10px;
          border: 1px solid rgba(128, 128, 128, 0.3);
        }

        .textarea-wrapper {
          position: relative;

          &::after {
            position: absolute;
            z-index: 50;
            top: 0px;
            right: -4px;
            font-family: FontAwesome;
            font-size: 9px;
            content: "\f005";
            color: #151e3a;
          }
        }

        textarea {
          margin-top: 10px;
          width: 100%;
          height: 100px;
          border: 1px solid rgba(128, 128, 128, 0.3);
        }
      }

      .btn-send-email {
        padding: 8px 18px;
        text-transform: uppercase;
        color: #fff;
        background-color: color('primary');
        font-size: 13px;
        font-weight: 300;

        i {
          padding-left: 10px;

          &:before {
            content: "\f0e0";
          }
        }
      }
    }

    p {
      font-size: 13px;
    }

    span {
      padding: 5px;
      position: relative;
      .fas.fa-star {

        &::before {
          position: absolute;
          top: 8px;
          left: 0px;
          content: "\f005";
          font-size: 9px;
          color: #151e3a;
        }

      }


    }


  }
}