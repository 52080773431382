body#property-show-map-view #map-canvas {
    height: 650px;
}

#property-show-map-view {
    #map-canvas {
        .property-card {
            margin-bottom: 1rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

#property-show-map-view {
    .search-box-triggers {
        @media(min-width: 769px) {
            margin: 0 !important;
        }
    }
}

